import React from 'react'

import Footer from '~/components/Footer/FooterGql'
import WeOfferSection from '~/components/landing-common/WeOfferSection'
import ExplanationSection from '~/components/landing-common/ExplanationSection'
import HeroSection from '~/components/landing-common/HeroSection/HeroSectionGql'
import FestivalMissionSection from '~/components/landing-cf/FestivalMissionSection'
import InstagramSection from '~/components/landing-cf/InstagramSection'
import VoucherSection from '~/components/landing-common/VoucherSection'
import LogosSection from '~/components/LogosSection/LogosSectionGql'

const OFFERS = {
  offer_1: 'percent',
  offer_2: 'book',
  offer_3: 'cocktail',
  offer_4: 'present',
  offer_5: 'guest',
  offer_6: 'time'
}

export default class Index extends React.Component {
  render () {
    const { isVoucher } = this.props

    return <div>
      <div className='container'>
        <HeroSection slides={['landing-cf/hero/cocktail.jpg']} heroWithDate heroWithPrice />
      </div>
      <div className='container'>
        <ExplanationSection />
      </div>
      {isVoucher &&
        <div className='container'>
          <VoucherSection />
        </div>}
      <FestivalMissionSection
        paragraphs={['paragraph_1', 'paragraph_2', 'paragraph_3']}
      />
      <div className='container'>
        <WeOfferSection offers={OFFERS} />
      </div>
      <InstagramSection />
      <div className='container'>
        <LogosSection loadImmediately />
      </div>
      <Footer />
    </div>
  }
}
